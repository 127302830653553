import styled from 'styled-components';

const Root = styled.div`
  // Adjust height equal to width
  ::before {
    content: "";
    display: inline-block;
    width: 0.1px;
    height: 0;
    padding-bottom: calc(100%);
  }

  // Center contents horizontally and vertically
  display: flex;
  align-items: center;
  
  cursor: pointer;
  
  // Add '&' to increase specificity
  &&:active {
    background-color: #FFFFFF;
    color: #FF2525;
    text-shadow: 0px 2px 5px rgba(255, 0, 0, 0.25), 0px 10px 25px rgba(222, 8, 8, 0.25);
  }

  > span {
    width: 100%;
    text-align: center;
    font-style: italic;
    font-size: min(6vw, 3rem); // Let font scale with window size
  }
`;

export default Root;
