import styled from 'styled-components';
import Root from 'src/components/atomic/Window/Root';

const Opened = styled(Root)`
  background-color: #F0F0F0;
  color: #FF2525;
  :hover {
    background-color: #F5E9E9;
    color: #FF2525;
    text-shadow: 0px 2px 5px rgba(255, 0, 0, 0.25), 0px 10px 25px rgba(222, 8, 8, 0.25);
  }
`;

export default Opened;
