import React from 'react';
import moment from 'moment';
import { useModal } from 'src/components/ModalProvider';
import ContentModal from 'src/components/ContentModal';
import Window from 'src/components/atomic/Window';

const today = moment().format(moment.HTML5_FMT.DATE);

interface IProps {
  date: string
}

const getStyledWindow = (date: string) => {
  if (date === today) return Window.Today;
  const day = Number(date.substr(8));
  if (date < today) {
    if (day % 2 == 1) return Window.Opened;
    return Window.OpenedAlt;
  }
  if (day % 2 == 1) return Window.Unopened;
  return Window.UnopenedAlt;
};

const CalendarWindow: React.FC<IProps> = ({ date }) => {
  const { showModal } = useModal();

  const handleClick = () => {
    showModal(<ContentModal date={date} />);
  };

  const StyledWindow = getStyledWindow(date);

  return (
    <StyledWindow onClick={handleClick}><span>{Number(date.substr(8))}</span></StyledWindow>
  );
};

export default CalendarWindow;
