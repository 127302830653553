import React from 'react';
import { useModal } from 'src/components/ModalProvider';
import styled from 'styled-components';

interface IProps {}

const Root = styled.div`
  position: fixed;
  background: rgba(0,0,0,0.75);
  top: 0; bottom: 0; left: 0; right: 0;
  overflow-y: auto;

  visibility: hidden;
  transition: opacity linear 0.1s;
  opacity: 0;

  &.visible {
    visibility: visible;
  }

  &.opaque {
    transition: opacity linear 0.25s;
    opacity: 1;
  }
`;

const ModalOverlay: React.FC<IProps> = ({ children }) => {
  const overlayRef = React.useRef<HTMLDivElement>(null);

  const [className, setClassName] = React.useState<string>();

  const { isVisible, hideModal } = useModal();

  const handleKeyDown = React.useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Escape') hideModal();
  }, []);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target === overlayRef.current) hideModal();
  }, []);

  React.useEffect(() => {
    if (isVisible) {
      setClassName('visible opaque'); // must be set after component mount to trigger fade in transition
      setTimeout(() => overlayRef.current?.focus(), 100); // must set focus after element is visible
    } else {
      setClassName('visible'); // remove opaque class to trigger fade out transition
      setTimeout(() => setClassName(undefined), 100); // this timeout must match fade out transition time
    }
  }, [isVisible]);

  return (
    <Root
      className={className}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      ref={overlayRef}
      tabIndex={-1} // make div focusable
    >
      {children}
    </Root>
  );
};

export default ModalOverlay;
