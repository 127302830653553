import styled from 'styled-components';
import Root from 'src/components/atomic/Window/Root';

const UnopenedAlt = styled(Root)`
  background-color: #E42121;
  color: #FFFFFF;
  :hover {
    background-color: #462727;
    color: #FFFFFF;
  }
`;

export default UnopenedAlt;
