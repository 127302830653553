import React from 'react';
import styled from 'styled-components';
import CalendarWindow from 'src/components/CalendarWindow';

interface IProps {}

const Root = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  > *:first-child {
    grid-column-start: 2;
  }
  
  > *:last-child {
    grid-column: span 3;

    background-color: white;
    color: #4C4C4C;

    padding: min(3vw, 1.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;

    * {
      font-size: min(2.5vw, 1.25rem);
      text-decoration: none;
    }
  }
`;

const dates = Array.from({ length: 24 }, (_, i) => '2020-12-' + String(i + 1).padStart(2, '0'));

const CalendarGrid: React.FC<IProps> = () => {
  return (
    <Root>
      {dates.map(date => <CalendarWindow key={date} date={date} />)}
      <div>
        <span>Julekalenderen er laget av&nbsp;<a href='https://sonat.no/' target="_blank">Sonat</a></span>
        <span>God jul!</span>
      </div>
    </Root>
  );
};

export default CalendarGrid;
