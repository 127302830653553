import React from 'react';
import styled from 'styled-components';

interface IProps {
  showErrors: boolean
  value: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error: string | undefined
}

const Root = styled.label`
  display: block;
  margin: min(5vw, 1rem) 0;
    
  > .text {
    display: flex;
    align-items: center;
    font-weight: bold;

    > input {
      margin: 0;
      margin-right: min(2.5vw, 0.5rem);
      width: min(5vw, 1rem);
      height: min(5vw, 1rem);
    }
  }

  > .error {
    display: block;
    margin-top: min(1vw, 0.2rem);
    color: #ff0000;
  }

`;

const CalendarCheckbox: React.FC<IProps> = ({ showErrors, value, onChange, error, children }) => {
  return (
    <Root>
      <span className='text'><input type='checkbox' checked={value} onChange={onChange} />{children}</span>
      {showErrors && <span className='error'>{error}</span>}
    </Root>
  );
};

export default CalendarCheckbox;
