import styled from 'styled-components';
import Root from 'src/components/atomic/Window/Root';

const Today = styled(Root)`
  background-color: #422525;
  box-shadow: inset 0px 10px 11px 2px rgba(0, 0, 0, 0.43);
  text-shadow: 0px -2px 6px rgba(255, 217, 118, 0.25);
  color: transparent;
  > span {
    background-image: linear-gradient(180deg, #F09326 31.51%, rgba(255, 238, 87, 0.99) 51.07%, rgba(203, 126, 54, 0.99) 76.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
  }
  :hover {
    background-color: #FF4848;
    color: #FFFFFF;
    text-shadow: 0px 2px 5px rgba(87, 0, 0, 0.25), 0px 10px 25px rgba(89, 1, 1, 0.25);
  }
`;

export default Today;
