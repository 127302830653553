import OpenedAlt from 'src/components/atomic/Window/OpenedAlt';
import Opened from 'src/components/atomic/Window/Opened';
import Today from 'src/components/atomic/Window/Today';
import UnopenedAlt from 'src/components/atomic/Window/UnopenedAlt';
import Unopened from 'src/components/atomic/Window/Unopened';

export default {
  OpenedAlt,
  Opened,
  Today,
  UnopenedAlt,
  Unopened,
};
