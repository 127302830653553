import React from 'react';
import styled from 'styled-components';

interface IProps {
  as?: 'input' | 'textarea'
  type?: 'checkbox' | 'radio'
  showErrors: boolean
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error: string | undefined
}

const Root = styled.label`
  display: block;
  margin: min(5vw, 1rem) 0;
    
  > .text {
    display: block;
    font-weight: bold;
    margin-bottom: min(2.5vw, 0.5rem);
  }
  
  > .error {
    display: block;
    margin-top: min(1vw, 0.2rem);
    color: #ff0000;
  }
  
  > input, textarea {
    width: 100%;
    height: min(10vw, 2rem);
    padding: min(2.5vw, 0.5rem);
    border: none;
    background-color: white;

    :focus {
      outline: none;
    }
  }

  > textarea {
    height: auto;
    min-height: min(40vw, 8rem);
  }
`;

const CalendarInput: React.FC<IProps> = ({ as = 'input', type, showErrors, value, onChange, error, children }) => {
  return (
    <Root>
      <span className='text'>{children}</span>
      {React.createElement(as, { type, value, onChange })}
      {showErrors && <span className='error'>{error}</span>}
    </Root>
  );
};

export default CalendarInput;
