
const baseURL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:5001/julekalender-2020/europe-west1/'
  : 'https://europe-west1-julekalender-2020.cloudfunctions.net/';

const addSubmission = (submission: CalendarSubmission) => fetch(baseURL + 'addSubmission', {
  method: 'POST',
  body: JSON.stringify(submission),
});

const getContent = () => fetch(baseURL + 'getContent', {
  method: 'GET',
}).then(res => res.json());

export default {
  addSubmission,
  getContent,
};
