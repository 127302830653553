import styled, { css } from 'styled-components';

const Button = styled.button`
  color: white;
  background-color: #FF2525;
  border: none;
  border-radius: 2rem;
  padding: 0.5rem 1rem;

  :hover {
    background-color: #da1d1d;
    cursor: pointer;
  }

  :focus {
    outline: none;
    background-color: #bd1c1c;
  }

  :active {
    background-color: #a11a1a;
  }

  ${({ disabled }) => disabled && css`
    , :hover {
      background-color: #f77171;
      cursor: initial;
    }
  `}
`;

export default Button;
