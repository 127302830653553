import React from 'react';
import { useContent } from 'src/components/ContentProvider';
import styled from 'styled-components';
import LogoImage from 'src/images/logo.png';
import moment from 'moment';
import 'moment/locale/nb';

moment.locale('nb');

interface IProps {
  date: string
}

const Root = styled.div`
  margin: auto;
  margin-top: min(4vw, 2rem);
  max-width: min(90vw, 25rem);
  min-height: min(150vw, 30rem);
  padding: min(5vw, 1rem);

  background-color: #FF2525;
  color: #FFFFFF;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > *:first-child {
    margin-top: 0;
  }

  > p:last-of-type {
    flex-grow: 1;
  }

  > .pretitle {
    font-weight: bold;
    letter-spacing: min(0.5vw, 0.25rem);
    text-transform: uppercase;
  }

  > .title {
    font-weight: bold;
    font-size: min(10vw, 2rem);
  }

  > .text {
    text-align: justify;
    white-space: pre-line;
  }

  > .subtext {
    align-self: flex-start;
  }

  > .sonat-logo {
    height: min(5vw, 1rem);
    filter: brightness(0) invert(1);
  }
`;

const ContentModal: React.FC<IProps> = ({ date }) => {
  const content = useContent(date);

  const title = content?.title || 'Enda ikke annonsert...';
  const text = content?.text || `Vinneren av luken blir trukket ${moment(date).add(12, 'hours').format('LLL')}.
  \nDet er ikke for sent å nominere den du vil skal vinne! Fyll inn registreringsskjemaet for å delta i trekningen.`;

  const price = '\n\nDagens julegave: 1 500 kr';
  const nominee = content?.name ? `\n\nNominert av: ${content.name}` : '';

  return (
    <Root>
      <p className="pretitle">Dagens vinner er:</p>
      <h1 className="title">{title}</h1>
      <p className="text">{text + price + nominee}</p>
      <img className="sonat-logo" src={LogoImage} />
    </Root>
  );
};

export default ContentModal;
