import React from 'react';
import styled from 'styled-components';
import { createFormScheme, withFormData } from 'minimal-form-data-hoc';
import API from 'src/API';
import Button from 'src/components/atomic/Button';
import CalendarInput from 'src/components/CalendarInput';
import CalendarCheckbox from 'src/components/CalendarCheckbox';

interface IProps {}

const Root = styled.div`
  max-width: min(90vw, 30rem);
  margin: auto;
  padding-bottom: 1rem;

  > form {
    > .actions {
      display: flex;
      justify-content: center;
    }

    > .footnote {
      text-align: justify;
      * {
        font-size: min(4vw, 0.8rem);
      }
    }
  }
`;

const scheme = createFormScheme({
  name: { value: '', rules: [
    ['Vennligst fyll inn navn', v => !v],
  ] },
  email: { value: '', rules: [
    ['Vennligst fyll inn e-post adresse', v => !v],
    ['Ugyldig e-post adresse', v => !/.+@.+\..+/.test(v)],
  ] },
  submission: { value: '', rules: [
    ['Vennligst fyll inn feltet', v => !v],
  ] },
  description: { value: '', rules: [
    ['Vennligst fyll inn feltet', v => !v],
  ] },
  dataSorageConsent: { value: true },
  newsletterConsent: { value: false },
});

const CalendarForm: React.FC<IProps> = withFormData(scheme)(({ data }) => {
  const [loading, setLoading] = React.useState(false);
  const [apiError, setApiError] = React.useState('');
  const [showErrors, setShowErrors] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (Object.values(data).some(field => field.error)) {
      setShowErrors(true);
    } else {
      setLoading(true);
      API.addSubmission({
        name: data.name.value,
        email: data.email.value,
        submission: data.submission.value,
        description: data.description.value,
        dataStorageConsent: data.dataSorageConsent.value,
        newsletterConsent: data.newsletterConsent.value,
      }).then(() => {
        setHasSubmitted(true);
      }).catch(() => {
        setApiError('Noe gikk galt under innsending. Prøv på nytt eller ta kontakt dersom feilen vedvarer.');
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  React.useEffect(() => {
    setShowErrors(false);
  }, [data]);

  if (hasSubmitted) {
    return (
      <Root>
        <h2 style={{ textAlign: 'center' }}>
          Takk for at du deltar i vår julekalender
        </h2>
        <p style={{ textAlign: 'justify' }}>
          Din registrering har blitt mottatt og organisasjonen vil være med i trekningen hver dag for å vinne en premie på 1 500 kr.
        </p>
      </Root>
    );
  }

  return (
    <Root>
      <form onSubmit={handleSubmit}>
        <CalendarInput {...data.name} showErrors={showErrors}>Navn</CalendarInput>
        <CalendarInput {...data.email} showErrors={showErrors}>E-post</CalendarInput>
        <CalendarInput {...data.submission} showErrors={showErrors}>Fyll inn organisasjon du ønsker å nominere</CalendarInput>
        <CalendarInput {...data.description} as='textarea' showErrors={showErrors}>Skriv litt om hvorfor du mener denne organisasjonen fortjener å vinne</CalendarInput>
        <CalendarCheckbox {...data.dataSorageConsent} showErrors={showErrors}>Jeg samtykker til at Sonat lagrer min kontaktinformasjon</CalendarCheckbox>
        <CalendarCheckbox {...data.newsletterConsent} showErrors={showErrors}>Jeg samtykker til at Sonat kan sende meg nyhetsbrev</CalendarCheckbox>
        {Boolean(apiError) && <p style={{ color: 'red' }}>{apiError}</p>}
        <div className='actions'>
          {loading
            ? <Button disabled>Laster...</Button>
            : <Button type='submit'>Send inn registrering</Button>}
        </div>
        <div className='footnote'>
          <p>
            Dersom du ikke samtykker til lagring av kontaktinformasjon kan du fortsatt delta i julekalenderen.
            Din kontaktinformasjon vil da bli slettet etter at alle trekninger er utført.
            Se <a href='https://sonat.no/index.php/personvernerklaering/' target='_blank'>personvernerklæring</a> for
            å vite mer om hvordan Sonat håndterer dine opplysninger, samt hvordan du kan oppdatere dine samtykker.
          </p>
        </div>
      </form>
    </Root>
  );
});

export default CalendarForm;
