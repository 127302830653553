import React from 'react';
import styled from 'styled-components';
import CalendarGrid from 'src/components/CalendarGrid';
import CalendarForm from 'src/components/CalendarForm';
import Tab from 'src/components/atomic/Tab';
import TabButton from 'src/components/atomic/TabButton';
import BackgroundImage from 'src/images/background.jpg';
import { ReactComponent as MistletoeImage } from 'src/images/mistletoe.svg';
import LogoImage from 'src/images/logo.png';

interface IProps {}

const Root = styled.div`
  max-width: 60rem;
  margin: auto;

  > .background {
    position: absolute;
    top: 0; left: 0; right: 0;
    z-index: -1;
    opacity: 0.3;

    img {
      display: block;
      width: 100%;
    }

    :after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      background: linear-gradient(0deg, #F0F0F0 10%, rgba(240, 240, 240, 0) 50%);
    }
  }

  > .navigation {
    display: flex;
    justify-content: center;
    padding-top: min(2vw, 2rem);
  }

  > .title {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    margin: min(5vw, 2rem) 0;
    
    h1 {
      color: #FF2525;
      font-style: italic;
      font-weight: bold;
      font-size: min(10vw, 5rem);
      margin: 0;
    }

    svg {
      width: min(10vw, 5rem);
      height: min(10vw, 5rem);
      margin: min(2vw, 1rem);
      :first-child {
        transform: rotate(-90deg);
      }
    }

    img {
      height: min(4vw, 2rem);
      /* margin-bottom: (-1vw); */
      filter: sepia(1) saturate(100);
    }
  }

  > .description {
    background-color: rgba(255, 255, 255, 0.6);
    max-width: min(90vw, 30rem);
    margin: min(10vw, 2rem) auto;
    padding: min(5vw, 1rem);
    text-align: justify;
  }

`;

const Calendar: React.FC<IProps> = () => {
  const [showWindows, setShowWindows] = React.useState(true);


  return (
    <Root>
      <div className='background'>
        <img src={BackgroundImage} />
      </div>
      <div className='navigation'>
        <TabButton active={showWindows} onClick={() => setShowWindows(true)}>Kalender</TabButton>
        <TabButton active={!showWindows} onClick={() => setShowWindows(false)}>Registrer</TabButton>
      </div>
      <div className='title'>
        <MistletoeImage />
        <div>
          <img src={LogoImage} />
          <h1>Julekalender</h1>
        </div>
        <MistletoeImage />
      </div>
      <div className='description'>
        I år vil Sonat gi en julegave hver dag til et godt formål.
        Vi oppfordrer våre kunder og ansatte til å komme med et forslag til hvem som skal delta i den daglige trekningen.
        For at forslaget skal være med i trekningen så må det være registrert innen kl. 24:00 dagen i forveien.
        Vinneren vil bli publisert i riktig luke kl. 12:00.
      </div>
      <Tab active={!showWindows}><CalendarForm /></Tab>
      <Tab active={showWindows}><CalendarGrid /></Tab>
    </Root>
  );
};

export default Calendar;
