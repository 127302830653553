import React from 'react';
import ModalOverlay from 'src/components/ModalOverlay';

type Component = React.ReactNode;

const ModalContext = React.createContext({
  isVisible: false,
  showModal: (component: Component): void => { throw new Error('Invalid context'); },
  hideModal: (): void => { throw new Error('Invalid context'); },
});

const ModalProvider: React.FC = ({ children }) => {

  const [isVisible, setVisible] = React.useState(false);
  const [component, setComponent] = React.useState<Component>(null);

  const showModal = React.useCallback((modal: Component) => {
    setComponent(modal);
    setVisible(true);
  }, []);

  const hideModal = React.useCallback(() => {
    // `component` is never nulled as this prevents transitions
    setVisible(false);
  }, []);

  return (
    <ModalContext.Provider value={{ isVisible, showModal, hideModal }}>
      {children}
      <ModalOverlay>
        {component}
      </ModalOverlay>
    </ModalContext.Provider>
  );
};

export const useModal = () => React.useContext(ModalContext);

export default ModalProvider;
