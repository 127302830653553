import React from 'react';
import Calendar from 'src/components/Calendar';
import ContentProvider from 'src/components/ContentProvider';
import ModalProvider from 'src/components/ModalProvider';

const App: React.FC = () => {
  return (
    <ContentProvider>
      <ModalProvider>
        <Calendar/>
      </ModalProvider>
    </ContentProvider>
  );
};

export default App;
