import React from 'react';
import API from 'src/API';

interface IProps {}

const ContentContext = React.createContext<CalendarContent>({});

const ContentProvider: React.FC<IProps> = ({ children }) => {
  const initial = React.useContext(ContentContext);
  const [content, setContent] = React.useState(initial);

  React.useEffect(() => {
    API.getContent().then(setContent);
  }, []);

  return (
    <ContentContext.Provider value={content}>
      {children}
    </ContentContext.Provider>
  );
};

export const useContent = (date: string) => React.useContext(ContentContext)[date];

export default ContentProvider;
