import styled, { css } from 'styled-components';

const TabButton = styled.button<{ active: boolean }>`
  font-weight: 800;
  font-size: min(5vw, 1rem);
  text-transform: uppercase;

  color: #666666;
  background-color: transparent;
  border: none;
  padding: min(2.5vw, 0.5rem) min(5vw, 1rem);
  margin: 0 min(1vw, 2rem);

  ${({ active }) => active && css`
    color: #333333;
    border-bottom: 1px solid;
  `}

  :hover {
    color: #555555;
  }

  :focus {
    outline: none;
    color: #444444;
  }
`;

export default TabButton;
